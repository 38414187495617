
body {
  background: #ffffff;  
  font-size: 16px;
  line-height: 1.25em;
  margin: 2em auto 4em auto;
  max-width: 1000px;
  padding: 0 2em;
  position: relative;
}

.header{
  margin: 0 0 2em 0; 
}


h1 {
  color: #121212;  
  font-size: 3em;  
  font-weight: 500;  
  /*letter-spacing: 0.125em;*/
  line-height: 1.125em; 
  margin: 1em 0 0.25em 0;
}

ul {
  list-style-type: none;
  margin: 1em 0 3em;
  padding: 0;
}
ul > li {
  display: inline-block;
  font-size: 1.25em;
  padding: 0 0.125em;
  margin: 0 0.5em 0 0;
}
ul > li > a {
  color: #39f;
  text-decoration: none;
}

h2 {
  color: #121212;
  font-size: 1.75em;
  font-weight: 500;
  margin: 0.5em  0 0;

}

h3 {
  font-size: 1em;
  font-weight: 400;
  margin: 0.5em 0 0;
}




body > p
{
  line-height: 1.5em;
  max-width: 720px;
}

body > aside > p:last-child {
  margin-bottom: 0;
}


a {
  color: #39f;
}

a:not(:hover) {
  text-decoration: none;
}



/* T L B R */

.icon {
  height: 24px;
  margin: 0 0.5em 1em 0; 
}

.post-container{
  margin: 2em 0 4em 0;
}

.postimg {
  border: solid 2px lightgray;
  margin: 0 0 0.5em 0;
  height: 135px;
  width: 750px;
  /*width: 100%;*/
  object-fit: cover; 
}

.post{
  margin: 0 0 2em 0;
}

/*https://alligator.io/css/cropping-images-object-fit/*/

.poketypes {
  object-position: 50% 20%;
}


.song {
  object-position: 0 20%;
}


.recolor {
  object-position: 0 20%;
}

.flockers {
  object-fit: none;
  object-position: 50% 10%;
}

.covert {
  object-fit: none;
  object-position: 30% 20%;
}

.maze {
  object-position: 0% 49%;
}

.minesweeper {
  object-position: 0% 10%;
}


.dab {
  object-position: 50% 40%;
  object-fit: none; 
}


.xray { 
  object-position: 0% 60%;  
}


.dataworks {  
  object-position: 0% 10%;
}


.battleship {  
  object-position: 0% 50%;
}



/* Extras */

.rap {
  object-position: 0 30%;  
}


.hubert {  
  object-position: 0 30%;  
}
